import { MEETS_TARGET, PARTIALLY_MEETS_TARGET } from '../../components/common';
import { ApiResponse } from '../../types/api';
import {
  SkillForVizopResponse,
  VisualizationScores,
  VisualizationSettings,
} from '../../types/vzop';
import axiosInstance from '../axios';

class VisualizationService {
  getVisualizationSettings(): Promise<ApiResponse<VisualizationSettings[]>> {
    return axiosInstance.get('/api/visualizationsettings').then((res) => {
      if (!res.data[0].partially_meets) {
        res.data[0].partially_meets = PARTIALLY_MEETS_TARGET.toString() + '%';
      }
      if (!res.data[0].meets_target) {
        res.data[0].meets_target = MEETS_TARGET.toString() + '%';
      }
      return res;
    });
  }
  editVisualizationSettings(body: {
    id: string;
    partially_meets: string;
    meets_target: string;
  }): Promise<ApiResponse<VisualizationSettings[]>> {
    return axiosInstance.patch(`/api/visualizationsettings`, body);
  }

  getIMScoresForUser(body: {
    user_id: string;
    plan_ids: string[];
    as_manager: boolean;
  }): Promise<ApiResponse<VisualizationScores>> {
    return axiosInstance.post<VisualizationScores>(
      `/api/visualizations/im/vizop_score/for_user`,
      body
    );
  }

  getIMScoresForPlan(body: {
    plan_id: string;
    user_ids: string[];
    as_manager: boolean;
  }): Promise<ApiResponse<VisualizationScores>> {
    return axiosInstance.post<VisualizationScores>(
      `/api/visualizations/im/vizop_score/for_plan`,
      body
    );
  }

  getIMSkillsData(body: {
    user_id: string;
    plan_id: string;
  }): Promise<ApiResponse<SkillForVizopResponse>> {
    const query = {
      user_id: body.user_id,
      plan_id: body.plan_id,
    };
    return axiosInstance.get('/api/visualizations/im', { params: query });
  }
}

export const visualizationService = new VisualizationService();
