import React, { useState, useCallback, useEffect } from "react";
import DraggableItem from "./draggablerow";

export interface Item {
  id: string;
  name: string;
  priority?: number | null;
  is_universal?: boolean;
  additionalColumns?: Record<string, any>;
}

interface DraggableListProps {
  items: Item[];
  showHeaders?: boolean;
  draggable?: boolean;
  onEdit?: (id: string, newName: string) => void;
  onDelete?: (id: string) => void;
  onDrop: (id: string, newIndex: number) => void;
}

const DraggableList: React.FC<DraggableListProps> = ({
  items,
  showHeaders = false,
  draggable = true,
  onEdit,
  onDelete,
  onDrop,
}) => {

  const [headers, setHeaders] = useState<string[]>(["Name"]);
  const [localItems, setLocalItems] = useState<Item[]>(items);
  const [draggedItem, setDraggedItem] = useState<{
    id: string;
    index: number;
  } | null>(null);

  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(()=>{
    setLocalItems(items)
  },[items])


  useEffect(() => {
    if (localItems[0] && localItems[0].additionalColumns) {
      let newHeaders: string[] = [];
      localItems[0].name.length
        ? (newHeaders = [...headers])
        : (newHeaders = []);
      for (let key of Object.keys(localItems[0].additionalColumns)) {
        newHeaders.push(`${key.slice(0, 1).toUpperCase() + key.slice(1)}`);
      }
      setHeaders(newHeaders);
    }
  }, [localItems]);

  const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setLocalItems((prevItems) => {
      const newItems = [...prevItems];
      const [removed] = newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, removed);
      setDraggedItem({ id: removed.id, index: hoverIndex });
      return newItems;
    });
  }, []);

  const handleDrop = useCallback(() => {
    if (draggedItem && isMounted) {
      onDrop(draggedItem.id, draggedItem.index);
    }
    setDraggedItem(null);
  }, [localItems, onDrop, draggedItem?.index, isMounted]);

  return (
    <>
      <div className="draggable-list-container">
        {showHeaders && (
          <div className="header-row">
            {headers.map((h, i) => (
              <div key={i}>{h}</div>
            ))}
            <div className="spacer"></div>
          </div>
        )}
        {localItems.map((item, index) => (
          <DraggableItem
            draggable={draggable}
            canEdit={!item.is_universal || item.is_universal === undefined}
            key={item.id}
            id={item.id}
            name={item.name}
            editableField="description"
            additionalHeaders={headers}
            additionalColumns={item.additionalColumns}
            index={index}
            moveRow={moveRow}
            onEdit={onEdit}
            onDelete={onDelete}
            onDrop={() => handleDrop()}
          />
        ))}
      </div>
    </>
  );
};

export default DraggableList;
