import { ApiResponse } from "../../types/api";
import {
  CopyPlanDTO,
  CreateIndividualPlanDTO,
  JobApplicantModel,
  JobSkillsModel,
  JobsModel,
  PlansForSurveyReturn,
  UpdatePlanResponse,
} from "../../types/job";
import axiosInstance from "../axios";

class PlansService {
  getPlans(include_archived?: boolean): Promise<ApiResponse<JobsModel[]>> {
    const query = include_archived !== undefined ? { include_archived } : {};
    return axiosInstance.get('/api/jobs', { params: query });
  }

  //TODO appears to do the same as get /jobs
  getAllPlans(): Promise<ApiResponse<JobsModel[]>> {
    return axiosInstance.get(`/api/jobs/get/getAllPlans`);
  }

  getPlanById(id: string): Promise<ApiResponse<JobsModel>> {
    return axiosInstance.get(`/api/jobs/${id}`);
  }

  getPlansForSurvey(): Promise<ApiResponse<PlansForSurveyReturn[]>> {
    return axiosInstance.get(`/api/jobs/get/getPlansForSurvey`);
  }

  createPlan(body: CreateIndividualPlanDTO | any): Promise<ApiResponse<any>> {
    return axiosInstance.post("/api/jobs", body);
  }

  copyPlan(body: CopyPlanDTO):Promise<ApiResponse<any>>{
    return axiosInstance.post('api/jobs/copy-plan', body)
  }

  updatePlan(
    body: Partial<JobsModel>
  ): Promise<ApiResponse<UpdatePlanResponse>> {
    if (!body.id) {
      throw new Error(`Cannot update plan without id. id is: ${body.id}`);
    }
    return axiosInstance.put(`/api/jobs/${body.id}`, body);
  }

  /**
   * Fetches all job applications for a particular user.
   * @param id
   * @returns
   */
  getApplicationsByUser(
    id: string
  ): Promise<ApiResponse<Partial<JobApplicantModel[]>>> {
    return axiosInstance.get(`/api/jobapplicant/user/${id}`);
  }

  createJobApplicant(body: {
    created_at?: Date;
    user_id: string;
    job_id: string;
    org_id?: string;
  }) {
    return axiosInstance.post(`/api/jobapplicant/`, body);
  }

  /**
   * Pins/favorites/stars a plan/job
   * @param id
   * @returns
   */
  createPin(id: string): Promise<ApiResponse<any>> {
    return axiosInstance.post(`/api/jobs/${id}/pin`);
  }

  deletePin(id: string): Promise<ApiResponse<any>> {
    return axiosInstance.delete(`/api/jobs/${id}/pin`);
  }

  //TODO update return type
  getPlanCategories(): Promise<ApiResponse<any>> {
    return axiosInstance.get(`/api/plancategory`);
  }

  updatePlanCategories(body: any): Promise<ApiResponse<any>> {
    return axiosInstance.put(`/api/plancategory/categories/update`, body);
  }

  setDefaultPlanCategory(category_id: number): Promise<ApiResponse<any>> {
    return axiosInstance.patch(`/api/plancategory/default`, {
      category_id: category_id,
    });
  }

  //does this archive one plan or multiple?
  //does this do anything other than update plans with the fl_archive field ??
  updatePlans(body: any) {
    return axiosInstance.put("/api/jobs/archive/saveplans", body);
  }

  updateJobSkills(body:  {job_skills: JobSkillsModel[]}) {
    return axiosInstance.put("/api/jobskill/update/updateJobSkills", body);
  }

  addFollowUser(props: { planId: string; userId: string }) {
    const { planId, userId } = props;
    return axiosInstance.post(`/api/planvisibility/${planId}/${userId}`);
  }

  deleteFollowUser(props: { planId: string; userId: string }) {
    const { planId, userId } = props;
    return axiosInstance.delete(`/api/planvisibility/${planId}/${userId}`);
  }
  //admin
  createPlansFromSkills(body: any) {
    return axiosInstance.post(
      `/api/jobs/create/createPlansFromMasterSkills`,
      body
    );
  }

  userCanViewPlan(
    planId: string
  ): Promise<ApiResponse<{ authorized: boolean }>> {
    return axiosInstance.get(`/api/planvisibility/usercanviewplan/${planId}`);
  }
}

export const plansService = new PlansService();
